
    import { Component, Prop, ProvideReactive, Vue, Watch } from 'vue-property-decorator';
    import TitleUtil from '@/components/TitleUtil';
    import CaseTitle from '@/components/title/CaseTitle.vue';
    import ProjectStatus from '@/components/case-manage/ProjectStatus.vue';
    import RemoveCase from '@/components/case-manage/RemoveCase.vue';
    import Study from '@/components/case-manage/Study.vue';
    import Plan from '@/components/case-manage/Plan.vue';
    import { CollectionRepresentation, LinkedRepresentation, LinkUtil } from 'semantic-link';
    import { CaseRepresentation } from '@/lib/api/representation/case/CaseRepresentation';
    import {
        StudyCollectionRepresentation,
        StudyRepresentation,
    } from '@/lib/api/representation/case/study/StudyRepresentation';
    import { PlanCollectionRepresentation, PlanRepresentation } from '@/lib/api/representation/case/plan/PlanRepresentation';
    import CaseStudyResource from '@/lib/api/resource/case/study/CaseStudyResource';
    import PlanResource from '@/lib/api/resource/case/plan/PlanResource';
    import CaseResource from '@/lib/api/resource/case/CaseResource';
    import Notifications from '@/components/notifications/Notifications.vue';

    import anylogger from 'anylogger';
    import { IsLoading } from '@/lib/LoadingDecorator';
    import LinkRelation from '@/lib/api/LinkRelation';
    import { Route } from 'vue-router';
    import { makeAbsolute } from '@/router/UriMapping';
    import Surgeon from '@/components/case-manage/Surgeon.vue';
    import UserResource from '@/lib/api/resource/user/UserResource';
    import { UserRepresentation } from '@/lib/api/representation/user/UserRepresentation';
    import SurgicalSpecificationResource
        from '@/lib/api/resource/case/surgical-specification/SurgicalSpecificationResource';
    import {
        SurgicalSpecificationRepresentation,
    } from '@/lib/api/representation/SurgicalSpecificationRepresentation';
    import { ProductPreferencesRepresentation } from '@/lib/api/representation/ProductPreferencesRepresentation';
    import SurgicalSpecificationLoader from '@/components/case-manage/SurgicalSpecificationLoader.vue';
    import SurgicalTemplateResource from '@/lib/api/resource/case/surgical-template/SurgicalTemplateResource';
    import {
        SurgicalTemplateCollectionRepresentation,
    } from '@/lib/api/representation/case/surgical-template/SurgicalTemplateCollectionRepresentation';
    import SurgicalTemplateLoader from '@/components/case-manage/SurgicalTemplateLoader.vue';
    import {
        SurgicalTemplateRepresentation,
    } from '@/lib/api/representation/case/surgical-template/SurgicalTemplateRepresentation';
    import {
        ComponentsCatalogRepresentation,
    } from '@/lib/api/representation/catalog/ComponentsCatalogRepresentation';
    import ApiResource from '@/lib/api/resource/ApiResource';
    import {
        getHipComponentsCatalog
    } from '@/lib/api/representation/global-catalog/HipComponentsCatalogRepresentation';

    const log = anylogger('Manage');

    enum ManagePages {
        General,
        Study,
        SurgicalTemplate,
        Plan,
    }

    const components = {
        ProjectStatus,
        Surgeon,
        RemoveCase,
        Study,
        Plan,
        SurgicalSpecificationLoader,
        SurgicalTemplateLoader,
    };

    @Component({ components })
    export default class Manage extends Vue {
        @Prop({ required: true })
        apiUri!: string;

        protected isLoading = true;
        /** The case (project) */
        protected project: CaseRepresentation | null = null;
        /** the active surgeon for the case */
        protected surgeon: UserRepresentation | null = null;
        protected userSurgicalTemplate: SurgicalTemplateRepresentation | null = null;
        protected acidSurgicalTemplate: SurgicalTemplateRepresentation | null = null;

        /**
         * Trying provide/inject pattern to avoid passing a prop to every component
         * when is only consumed on the leaf components
         */
        @ProvideReactive()
        protected componentsCatalog: ComponentsCatalogRepresentation | null = null;

        protected studyCollection: StudyCollectionRepresentation | null = null;
        protected planCollection: PlanCollectionRepresentation | null = null;

        /** the list of **all** surgical specification for the case */
        protected surgicalSpecificationCollection: CollectionRepresentation<SurgicalSpecificationRepresentation<ProductPreferencesRepresentation>> | null = null;

        /** the list of **all** surgical templates for the case */
        protected surgicalTemplateCollection: SurgicalTemplateCollectionRepresentation | null = null;

        protected items = [
            { title: 'General', id: ManagePages.General },
            { title: 'Study', id: ManagePages.Study },
            { title: 'Surgical Templates', id: ManagePages.SurgicalTemplate },
            { title: 'Plans', id: ManagePages.Plan },
        ];

        protected managePages = ManagePages;

        protected currentPage = this.items[0].id;

        protected get studies(): StudyRepresentation[] {
            return this.studyCollection?.items ? this.studyCollection.items : [];
        }

        protected get plans(): PlanRepresentation[] {
            return this.planCollection?.items ? this.planCollection.items : [];
        }

        protected get surgicalSpecifications(): SurgicalSpecificationRepresentation<ProductPreferencesRepresentation>[] {
            return this.surgicalSpecificationCollection?.items ? this.surgicalSpecificationCollection.items : [];
        }

        protected get surgicalTemplates(): SurgicalTemplateRepresentation [] {
            return this.surgicalTemplateCollection?.items ? this.surgicalTemplateCollection.items : [];
        }

        protected get currentSurgicalTemplates(): SurgicalTemplateRepresentation [] {
            return this.surgicalTemplates.filter((template: SurgicalTemplateRepresentation) => {
                return LinkUtil.getUri(template, LinkRelation.self) === this.userSurgicalTemplateUri ||
                    LinkUtil.getUri(template, LinkRelation.self) === this.acidSurgicalTemplateUri;
            });
        }

        protected get legacySurgicalTemplates(): SurgicalTemplateRepresentation [] {
            return this.surgicalTemplates.filter((template: SurgicalTemplateRepresentation) => {
                return LinkUtil.getUri(template, LinkRelation.self) !== this.userSurgicalTemplateUri &&
                    LinkUtil.getUri(template, LinkRelation.self) !== this.acidSurgicalTemplateUri;
            });
        }

        protected getStudySelf(study: StudyRepresentation): string {
            return LinkUtil.getUri(study, LinkRelation.self) || study.series || study.name;
        }

        protected getPlanSelf(plan: PlanRepresentation): string {
            return LinkUtil.getUri(plan, LinkRelation.canonicalOrSelf) || '';
        }

        protected getResourceSelf(representation: LinkedRepresentation): string {
            return LinkUtil.getUri(representation, LinkRelation.canonicalOrSelf) || '';
        }

        protected get userSurgicalSpecificationUri(): string | null {
            if (this.project) {
                const uri = LinkUtil.getUri(this.project, LinkRelation.surgicalSpecification);
                if (uri) {
                    return uri;
                }
            }
            return null;
        }

        protected get userSurgicalTemplateUri(): string | null {
            if (this.project) {
                const uri = LinkUtil.getUri(this.project, LinkRelation.currentSurgicalTemplate);
                if (uri) {
                    return uri;
                }
            }
            return null;
        }

        protected get acidSurgicalTemplateUri(): string | null {
            if (this.project) {
                const uri = LinkUtil.getUri(this.project, LinkRelation.acidSurgicalTemplate);
                if (uri) {
                    return uri;
                }
            }
            return null;
        }

        /** Get the link of the active study, or null if it is not available or unknown. */
        protected get activeStudyUri(): string | null {
            if (this.project) {
                const uri = LinkUtil.getUri(this.project, LinkRelation.activeStudy);
                if (uri) {
                    return uri;
                }
            }
            return null;
        }

        @Watch('$route')
        @IsLoading('loading')
        protected async onRouteChange(to: Route, from: Route): Promise<void> {
            log.info('Change from %s to %s', from.params.apiUri, to.params.apiUri);
            return await this.loadCase(makeAbsolute(to.params.apiUri));
        }

        @IsLoading('isLoading')
        protected async mounted(): Promise<void> {
            await this.loadCase(this.apiUri);
        }

        private async loadCase(apiUri: string): Promise<void> {
            await ApiResource.getApi(this.$api, this.$apiOptions);

            // Load case (project) data
            this.project = await CaseResource.getCaseByUri(this.$api, apiUri, this.$apiOptions);

            // emit the project so that the title bar can show the project title and track
            // the project state to update the stepper
            TitleUtil.$emit(this, {
                titleComponentType: CaseTitle,
                titleData: { project: this.project },
                notificationComponentType: Notifications,
                notificationData: { project: this.project },
            });

            if (this.project) {
                // get the list of studies
                this.studyCollection = await CaseStudyResource.getCaseStudies(
                    this.project,
                    { ...this.$apiOptions, ...{ includeItems: true } });

                // get the optional case surgeon
                this.surgeon = await UserResource.getCaseSurgeon(this.$api, this.project, this.$apiOptions);

                // the list of all plans on a case (IMPORTANT: this should include both the 'acid' plan
                // and the 'user' plan, but the list is not constrained to two plans)
                this.planCollection = await PlanResource.getCasePlans(
                    this.project, { ...this.$apiOptions, forceLoad: true, includeItems: true });

                // get the list of all surgical specifications. There should be an single surgical spec that is
                // referenced by both the acid surgical template and potentially the 'user' surgical template
                // if the user has performed 3d templating.
                this.surgicalSpecificationCollection = await SurgicalSpecificationResource.getCaseSurgicalSpecifications(
                    this.project, { ...this.$apiOptions, forceLoad: true, includeItems: false });

                this.surgicalTemplateCollection = await SurgicalTemplateResource.getCaseSurgicalTemplates(
                    this.project, { ...this.$apiOptions, forceLoad: true, includeItems: false });

                this.userSurgicalTemplate = await SurgicalTemplateResource.getCaseUserSurgicalTemplate(
                    this.project, this.$apiOptions);
                this.acidSurgicalTemplate = await SurgicalTemplateResource.getCaseAutomatedSurgicalTemplate(
                    this.project, this.$apiOptions);

                this.componentsCatalog = await getHipComponentsCatalog(this.$api, this.$apiOptions);
            } else {
                log.warn('Failed to load case');
            }
        }
    }
