import { Matrix4, Vector3 } from 'three';
import { degToRad } from 'three/src/math/MathUtils';
import Implant3d from '@/hipPlanner/assembly/objects/Implant3d';
import GroupObject from '@/hipPlanner/assembly/objects/GroupObject';
import { BodySide } from '@/lib/api/representation/interfaces';

import anylogger from 'anylogger';
import { isRigid, makeMatrix, positionalPart, RigidMatrix4, RigidTransform } from '@/lib/base/RigidTransform';
import assert from 'assert';
import { approxEquals, asVector3 } from '@/lib/base/ThreeUtil';
import { makeFemoralAssembly } from '@/hipPlanner/assembly/controllers/femoralAssembly';
import { StemRotationChange, StemTranslationChange } from '@/hipPlanner/stores/planner/stemTransformations';
import { formatNumberSignPrecision } from '@/lib/filters/format/formatNumberSignPrecision';
import { HipImplantAlias } from '@/hipPlanner/assembly/objects/HipImplantAlias';
import { calculateHeadTransform, loadImplant } from '@/hipPlanner/assembly/controllers/implantLoading';
import { AxiosInstance } from 'axios';
import { CaseCup, CaseHead, CaseLiner, CaseStem } from '@/hipPlanner/components/state/types';
import { SceneAssembly } from '@/lib/planning/viewer/SceneAssembly';
import { MeasurementsRepresentation } from '@/lib/api/representation/case/measurements/MeasurementsRepresentation';
import { HipLinerRepresentation } from '@/lib/api/representation/case/hip/HipLinerRepresentation';
import { hasStemRotationAxes, makeStemAssembly } from '@/hipPlanner/assembly/controllers/hipStemAssembly';
import { AcetabularAssembly, HipPlannerAssembly } from '@/hipPlanner/assembly/HipPlannerAssembly';
import { Bones } from '@/lib/constants/Bones';
import Bone3d from '@/hipPlanner/assembly/objects/Bone3d';
import { HipHeadRepresentation } from '@/lib/api/representation/case/hip/HipHeadRepresentation';
import ViewerUtils from '@/lib/planning/viewer/ViewerUtils';
import { getPosition } from '@/lib/base/matrix';
import AxisObject from '@/hipPlanner/assembly/objects/AxisObject';
import {
    formatDegrees,
    formatMatrixBasis,
    formatMatrixEuler,
    formatRadians,
    formatVector,
} from '@/lib/base/formatMath';
import CoordinatesObject from '@/hipPlanner/assembly/objects/CoordinatesObject';
import { validationLogger } from '@/lib/logging/validationLogging';

const log = anylogger('hipPlannerAssembly');
const logValidation = validationLogger(log);

const ACETABULAR_GROUP_COLOR = '#f11d5c';
const CUP_GROUP_COLOR = '#8866f5';

export enum HipAssembly {
    AcetabularGroup = 'acetabular-group',
    CupGroup = 'cup-group',
    StemGroup = 'stem-group',
    FemoralGroup = 'femoral-group',
}

export async function loadHipPlannerAssembly(
    sceneAssembly: SceneAssembly,
    axios: AxiosInstance,
    studyMeasurements: MeasurementsRepresentation,
    caseCup: CaseCup,
    caseStem: CaseStem,
    caseLiner: CaseLiner,
    caseHead: CaseHead,
    side: BodySide,
    cancelSignal?: AbortSignal): Promise<HipPlannerAssembly> {
    log.info('Loading hip-planner assembly');

    // Load the cup and stem components
    cancelSignal?.throwIfAborted();
    const cup = await loadImplant(axios, caseCup, HipImplantAlias.Cup);
    cancelSignal?.throwIfAborted();
    const liner = await loadImplant(axios, caseLiner, HipImplantAlias.Liner);
    cancelSignal?.throwIfAborted();
    const head = await loadImplant(axios, caseHead, HipImplantAlias.Head);
    cancelSignal?.throwIfAborted();
    const stem = await loadImplant(axios, caseStem, HipImplantAlias.Stem);

    // Find the femur and inner-cortical-surface in the scene-assembly
    const femur = sceneAssembly.findObject(Bones.OperativeFemur) as Bone3d;
    assert.ok(!!femur, 'Femur model is required to make femoral assembly');

    const femurInner = sceneAssembly.findObject(Bones.InnerSurface) as Bone3d;
    assert.ok(!!femurInner, 'Femur inner cortical model is required to make the femoral assembly');

    const acetabularPosition = getAcetabularGroupPosition(caseLiner);
    const femoralPosition = getFemoralGroupPosition(caseHead);

    const assembly = makeHipPlannerAssembly(
        { cup, liner, head, stem, femur, femurInner },
        acetabularPosition,
        femoralPosition,
        side,
        studyMeasurements,
        caseStem,
    );

    sceneAssembly.addToHipObject(assembly.root);

    return assembly;
}

export type HipPlannerObjects = {
    cup: Implant3d,
    liner: Implant3d,
    head: Implant3d,
    stem: Implant3d,
    femur: Bone3d,
    femurInner: Bone3d
}

export function makeHipPlannerAssembly(
    objects: HipPlannerObjects,
    acetabularPosition: Vector3,
    femoralPosition: Vector3,
    side: BodySide,
    studyMeasurements: MeasurementsRepresentation,
    caseStem: CaseStem): HipPlannerAssembly {
    const { cup, liner, head, stem, femur, femurInner } = objects;

    const root = new GroupObject({ name: 'Root' });

    const femoralAssembly = makeFemoralAssembly(femoralPosition, femur, femurInner, studyMeasurements, side);
    root.attach(femoralAssembly.femoralGroup);

    const acetabularAssembly = makeAcetabularAssembly(acetabularPosition, cup, liner);
    root.add(acetabularAssembly.acetabularGroup);

    const stemAssembly = makeStemAssembly(femoralAssembly.nativeTransform, head, stem, caseStem);
    femoralAssembly.femoralGroup.attach(stemAssembly.stemGroup);

    const alignmentCoordinates = new CoordinatesObject({ visible: false });
    root.add(alignmentCoordinates);

    const ctCoordinates = new CoordinatesObject({ visible: false });
    root.add(ctCoordinates);

    const assembly: HipPlannerAssembly = {
        root,
        alignmentCoordinates,
        ctCoordinates,
        femoralAssembly,
        ...acetabularAssembly,
        ...stemAssembly,
        side,
        isInNativePosition: true,
    };

    updateCupAndLiner(assembly, acetabularPosition, cup, liner);

    return assembly;
}

/** True if the assembly has the properties necessary to do stem-transformation */
export function shouldEnableStemTransform(assembly: HipPlannerAssembly): boolean {
    return assembly.femoralAssembly.isAligned && hasStemRotationAxes(assembly);
}

function makeAcetabularAssembly(position: Vector3, cup: Implant3d, liner: Implant3d): AcetabularAssembly {
    const acetabularGroup = new GroupObject({
        name: HipAssembly.AcetabularGroup,
        transform: new Matrix4().setPosition(position),
        debugColor: ACETABULAR_GROUP_COLOR,
    });
    const cupGroup = new GroupObject({
        name: HipAssembly.CupGroup,
        debugColor: CUP_GROUP_COLOR,
    });
    acetabularGroup.add(cupGroup);
    cupGroup.attach(cup);
    cupGroup.attach(liner);

    return { acetabularGroup, cupGroup, cup, liner };
}

/**
 * The position of the cup centre-of-rotation in the world.
 * TODO: I think that because the acetabular group is the child of the scene then this is always
 *   the same as the cup position. If so combine the world and local position.
 */
export function cupWorldPosition(assembly: AcetabularAssembly): Vector3 {
    return assembly.acetabularGroup.worldPosition;
}

/**
 * The position of the cup centre-of-rotation in local-coordinates.
 */
export function cupPosition(assembly: AcetabularAssembly): Vector3 {
    return assembly.acetabularGroup.localPosition;
}

/**
 * Change the position of the cup by setting its centre-of-rotation.
 */
export function setCupPosition(assembly: AcetabularAssembly, value: Vector3): void {
    assembly.acetabularGroup.localPosition = value;
}

/**
 * The head centre in native position (aka: templated head centre) is the world coordinates of the head
 * while the femur is in the 'native' configuration.
 *
 * This function can be called independently whether the assembly is currently in the 'native' or 'retracted'
 * configuration.
 */
export function templatedHeadCentre(assembly: HipPlannerAssembly): Vector3 {
    const { stemGroup, femoralAssembly: { femoralGroup } } = assembly;
    const stemTransform = assembly.isInNativePosition ?
        stemGroup.localTransform :
        femoralGroup.localTransform.invert();
    const result = positionalPart(stemTransform).applyMatrix4(assembly.femoralAssembly.nativeTransform);

    if (assembly.isInNativePosition) {
        assert.ok(approxEquals(result, assembly.stemGroup.worldPosition));
    }
    return result;
}

/**
 * Set the stem-transform relative to the femur.
 */
export function setStemTransform(assembly: HipPlannerAssembly, value: RigidTransform) {
    // This function currently only needs to be invoked when we are in native arrangement. With some more
    // work we could make it work in retracted arrangement as well.
    if (assembly.isInNativePosition) {
        const transform = makeMatrix(value);
        assembly.stemGroup.localTransform = transform;
        if (value.matrix === null) {
            log.info('Resetting manual-stem-transform');
        }
        logValidation('Stem-group local transform set to:\n%s', formatMatrixEuler(transform, { indent: 2}));
    } else {
        throw Error('Attempting to set stem position when not in native position');
    }
}

/**
 * Calculate the position of the stem-assembly after the given change to translation.
 */
export function translatedStemTransform(
    assembly: HipPlannerAssembly, change: Partial<StemTranslationChange>): RigidMatrix4 {
    assert.ok(assembly.femoralAssembly.isAligned, 'Cannot translate unaligned stem.');
    assert.ok(assembly.isInNativePosition,
        'Cannot translate stem when assembly is not in native configuration.');

    for (const [key, value] of Object.entries(change)) {
        log.info('Translating stem in \'%s\' direction by %s mm', key, formatNumberSignPrecision(value));
    }

    const [lateral, anterior, superior] = [change.ml ?? 0, change.pa ?? 0, change.is ?? 0];

    // In a left-side case the medial-to-lateral direction is to the left
    const left = assembly.side === BodySide.Left ? lateral : -lateral;

    const newPosition = new Vector3()
        .setFromMatrixPosition(assembly.stemGroup.theObject.matrix)
        .add(new Vector3(left, -anterior, superior));

    return assembly.stemGroup.theObject.matrix.clone()
        .setPosition(newPosition);
}

/**
 * Calculate the transform of the stem-rotation-group after the given change to rotation
 */
export function rotatedStemTransform(
    assembly: HipPlannerAssembly, change: Partial<StemRotationChange>): RigidMatrix4 {
    assert.ok(!!assembly.paAxis && !!assembly.stemNeckAxis && !!assembly.stemShaftAxis,
        'Cannot calculate stem rotation without stem-axis info');
    assert.ok(assembly.isInNativePosition,
        'Cannot calculate stem rotation when assembly is not in native configuration.');

    const [ef, ra, vv] = [change.ef ?? 0, change.ra ?? 0, change.vv ?? 0];

    // The rotation angles depend on whether this is a left or right-side case
    const [neckShaftRotation, stemShaftRotation, paRotation] =
        assembly.side === BodySide.Left ?
            [-ef, ra, -vv] :
            [ef, -ra, vv];

    return assembly.stemGroup.theObject.matrix.clone()
        .multiply(rotationAroundAxis('extension-flexion', assembly.stemNeckAxis, neckShaftRotation))
        .multiply(rotationAroundAxis('retroversion-anteversion', assembly.stemShaftAxis, stemShaftRotation))
        .multiply(rotationAroundAxis('varus-valgus', assembly.paAxis, paRotation));
}

/**
 * Create a matrix that rotates around the given axis by the given angle in degrees
 */
function rotationAroundAxis(name: string, axis: AxisObject, angleDegrees: number): Matrix4 {
    if (angleDegrees === 0) {
        // If the angle is zero there is no rotation to do
        return new Matrix4();
    }
    log.info('Applying %s rotation around \'%s\' axis by %s degrees', name, axis.name, formatNumberSignPrecision(angleDegrees));

    return new Matrix4()
        .multiply(axis.matrix)
        .multiply(new Matrix4().makeRotationY(degToRad(angleDegrees)))
        .multiply(axis.matrix.clone().invert());
}

/**
 * Set the position of the acetabular-assembly and replace the cup and liner objects inside it.
 */
export function updateCupAndLiner(
    assembly: HipPlannerAssembly,
    acetabularPosition: Vector3,
    cup: Implant3d,
    liner: Implant3d): void {
    log.info('Updating cup and liner. New acetabular position is %s', formatVector(acetabularPosition));

    // Get the world-transform of the given cup and liner
    const cupTransform = cup.worldTransform;
    const linerTransform = liner.worldTransform;

    // Set the position of the acetabular-assembly
    assembly.acetabularGroup.worldPosition = acetabularPosition;

    // Move the stem and femur assemblies to the retracted position. If the may have changed the
    // parent transform of the assembly we want to move it to one of the defined states.
    if (assembly.isInNativePosition) {
        log.info('Changing planner-assembly to retracted arrangement to update cup and liner');
        moveToRetractedPosition(assembly);
    }

    // Transform the cupGroup so that it has the same rotation as the new cup, but shares the new position of the
    // acetabular-assembly
    assembly.cupGroup.worldTransform = cupTransform.clone().setPosition(acetabularPosition);

    // Swap the old cup and liner for the new if they are different.
    if (cup !== assembly.cup) {
        assembly.cupGroup.detach(assembly.cup);
        assembly.cup = cup;
        assembly.cupGroup.attach(cup);
    }
    if (liner !== assembly.liner) {
        assembly.cupGroup.detach(assembly.liner);
        assembly.liner = liner;
        assembly.cupGroup.attach(liner);
    }

    // Assign the world-transform of the cup and liner
    cup.worldTransform = cupTransform;
    liner.worldTransform = linerTransform;
}

/**
 * Orient the cup group of the assembly according to the anatomic definition of anteversion and abduction matching
 * that done by server code. Rather than compute the transformation via a 'plane' like the compute function, this
 * directly calculates the transformation that the plane implicitly represents.
 *
 * @see the function 'acid.lib.atlas.acetabularcup.acetabular_cup.make_cup_plane_anatomic' in the python code.
 */
export function orientCupGroupAnatomic(
    assembly: HipPlannerAssembly,
    anteversion: number,
    inclination: number,
    siVector: Vector3,
    apVector: Vector3): void {
    // Convert angles in radians
    let atv = degToRad(anteversion);
    let inc = degToRad(inclination);

    // Adjust rotation direction for different sides
    if (assembly.side === BodySide.Right) {
        atv = -atv;
        inc = -inc;
    }

    const paVector = apVector.clone().negate();

    // In the compute code the transformation is implicit in the calculation of the normal, x and y vectors of
    // the 'cup plane', so those variable names are retained here.
    const normal = siVector.clone()
        .applyAxisAngle(paVector, inc)
        .applyAxisAngle(siVector, atv)
        .normalize()
        .negate();
    const x = new Vector3().crossVectors(paVector, normal).normalize();
    const y = new Vector3().crossVectors(normal, x).normalize();

    // This is the mapping from a 'plane' to a transform matrix that is applied in this python function:
    // acid.lib.atlas.acetabularcup.acetabular_cup.AcetabularCupAtlas.transform_2_plane
    const rotation = new Matrix4().makeBasis(x.negate(), normal, y);
    assert.ok(isRigid(rotation), 'Not a rigid transform');

    logValidation([
        'Cup rotation calculation:',
        `  anteversion: ${formatRadians(atv)} (${formatDegrees(atv)})`,
        `  inclination: ${formatRadians(inc)} (${formatDegrees(inc)})`,
        '  anatomical basis:',
        `    si: ${formatVector(siVector)}`,
        `    ap: ${formatVector(apVector)}`,
        `  cup-normal: ${formatVector(normal)}`,
        `  cup-x: ${formatVector(x)}`,
        `  cup-y: ${formatVector(y)}`,
    ].join('\n'));

    // Set the rotation of the cupGroup
    assembly.cupGroup.localTransform = rotation.setPosition(assembly.cupGroup.localPosition);
}

/**
 * Change the stem and femoral groups to the 'retracted' arrangement:
 * - The stem-group containing the stem and head is attached to the acetabular-group
 * - The femoral-group is attached to the stem-group
 *
 * The transformations of the objects are such that:
 * - The translation of the stem connects the head into the cup
 * - The rotation of the stem and the transformation of the femur show the placement of the stem relative
 *   to the femur while keeping the femur in its native rotation
 */
export function moveToRetractedPosition(assembly: HipPlannerAssembly): void {
    const { stemGroup, acetabularGroup, femoralAssembly: { femoralGroup } } = assembly;

    // The acetabular-group is at the retracted position for the stem-group.
    //  Since the stem-group will be a child of the acetabular-group this means its local-position will be zero
    const retractedLocalPosition = new Vector3();

    if (!assembly.isInNativePosition) {
        // Assembly is already in the retracted arrangement
        // Check that the stem-group is already in retracted position
        if (approxEquals(retractedLocalPosition, stemGroup.localPosition)) {
            log.debug(
                'Planner-assembly already in retracted arrangement, and stem-group already in retracted position');
        } else {
            stemGroup.localPosition = retractedLocalPosition;
            log.warn(
                'Planner-assembly was in retracted arrangement, but stem-group was not in retracted position. ' +
                'Setting stem-group position to %s',
                formatVector(acetabularGroup.worldPosition));
        }
        return;
    }

    // Switch the assembly from native to retracted arrangement
    acetabularGroup.attach(stemGroup);
    stemGroup.attach(femoralGroup);

    // Set the stem-group to its retracted position
    stemGroup.localPosition = retractedLocalPosition;

    assembly.isInNativePosition = false;

    logStemTransforms('Planner-assembly changed to retracted arrangement.', assembly);
}

/**
 * Move the stem and femoral groups to their 'native' arrangement:
 * - The femoral-group is attached to the scene
 * - The stem-group containing the stem and head is attached to the femoral-group
 *
 * The transformations of the objects are such that:
 * - The femur is in its native position and orientation
 * - The stem is placed relative to the femur
 */
export function moveToNativePosition(assembly: HipPlannerAssembly): void {
    const { root, stemGroup, femoralAssembly: { femoralGroup, nativeTransform } } = assembly;

    // Native position for the femoral group
    const nativePosition = getPosition(nativeTransform);

    if (assembly.isInNativePosition) {
        // Assembly is already in the native arrangement
        // Check that the femoral group is in native position
        if (approxEquals(nativePosition, femoralGroup.worldPosition)) {
            log.debug('Planner-assembly already in native arrangement, and femoral-group already in native position');
        } else {
            femoralGroup.worldPosition = nativePosition;
            log.warn(
                'Planner-assembly was in native arrangement, but femoral-group was not in native position. ' +
                'Setting femoral-group to native position %s',
                formatVector(nativePosition));
        }
        return;
    }

    // Switch the assembly from retracted to native arrangement
    root.attach(femoralGroup);
    femoralGroup.attach(stemGroup);

    // Set the femoral-group back to original native position
    femoralGroup.worldPosition = nativePosition;

    assembly.isInNativePosition = true;

    logStemTransforms(
        'Planner-assembly changed to native arrangement, and femoral-group set to native position ' +
        formatVector(nativePosition),
        assembly,
    );
}

/** Change the stem assembly with a newly selected stem */
export async function loadAndReplaceStemAndHead(
    assembly: HipPlannerAssembly,
    axios: AxiosInstance,
    stem: CaseStem,
    head: CaseHead,
    resetStemTransform: boolean): Promise<void> {
    const stemImplant = await loadImplant(axios, stem, HipImplantAlias.Stem);
    const headImplant = await loadImplant(axios, head, HipImplantAlias.Head, calculateHeadTransform(stem, head));

    replaceStemAndHead(assembly, stemImplant, headImplant, stem, resetStemTransform);
}

/**
 * Set the stem and head implants in the StemAssembly, along with the transformation of the stem relative to the
 * femur. This will also implicitly also set the position of the native transform of the femoral assembly,
 * which does not change the transformation of the femur in world-space, but affects how the stem is positioned
 * relative to it.
 *
 * When doing stem and/or head planning, the stem/head is plugged into the femur.
 * The femur needs to be in the native (a.k.a. "patient", "ct scan") position, given all
 * the input data we are dealing with (head, stem transformations) is defined
 * in the native/patient coordinate system.
 *   - When the stem panel is open, the femur is already in the native position
 *   - When the combined panel is open, the femur needs to be moved to native position when being assembly
 *
 * @param assembly the main planner assembly
 * @param stem a new stem implant
 * @param head a new head implant
 * @param caseStem additional data about the stem, used to create various additional geometric features
 * @param resetStemTransform
 */
export function replaceStemAndHead(
    assembly: HipPlannerAssembly,
    stem: Implant3d,
    head: Implant3d,
    caseStem: CaseStem,
    resetStemTransform = true): void {
    log.info('Replacing the stem and head in assembly');

    doInNativePosition('replacing the stem and head', assembly, () => {
        const { femoralGroup, nativeTransform } = assembly.femoralAssembly;

        // The new native femoral transform should have the same orientation as the existing one, which
        // is aligned to the femoral coordinate system, but with a position corresponding to the new native head.
        const newFemoralTransform = nativeTransform.clone().setPosition(head.worldPosition);

        let stemTransform: Matrix4;
        if (resetStemTransform) {
            stemTransform = new Matrix4();
        } else {
            stemTransform = newFemoralTransform.clone().invert()
                .multiply(assembly.stem.worldTransform)
                .multiply(stem.worldTransform.invert())
                .multiply(newFemoralTransform);
        }

        // Detach the old stem-group
        femoralGroup.detach(assembly.stemGroup);
        // TODO: should maybe also dispose of old stem and head geometry etc here

        // Shift the femoral group to correspond to the new head position
        femoralGroup.shiftWorldTransform(newFemoralTransform);
        assembly.femoralAssembly.nativeTransform = newFemoralTransform;

        // Create a new stem-assembly using the new head and stem
        const newStemAssembly = makeStemAssembly(newFemoralTransform, head, stem, caseStem);
        femoralGroup.attach(newStemAssembly.stemGroup);

        // Move the new stem-assembly to account for the femoral and stem-transformations
        newStemAssembly.stemGroup.worldTransform = newFemoralTransform.clone().multiply(stemTransform);

        // Merge the new stem assembly into the existing assembly
        Object.assign(assembly, newStemAssembly);
    });
}

/**
 * Invoke the given action callback with the planner-assembly in native position.
 *
 * - If the assembly is already in native position the action will be invoked
 * - If the assembly is in retracted position it will be moved to native, the action invoked,
 *   and then the assembly will be move back to retracted
 */
export function doInNativePosition(reason: string, assembly: HipPlannerAssembly, action: () => void): void {
    let wasInRetractedPosition: boolean;
    if (assembly.isInNativePosition) {
        wasInRetractedPosition = false;
    } else {
        log.info('Changing planner assembly to native-arrangement (%s)', reason);
        moveToNativePosition(assembly);
        wasInRetractedPosition = true;
    }

    action();

    if (wasInRetractedPosition) {
        log.info('Changing planner assembly back to retracted-arrangement (finished %s)', reason);
        moveToRetractedPosition(assembly);
    }
}

function logCupTransforms(message: string, assembly: HipPlannerAssembly) {
    logValidation(
        '%s\n' +
        '  position: %s\n' +
        '  cup-group transform:\n%s\n' +
        '  cup transform:\n%s\n' +
        '  liner transform:\n%s',
        message,
        formatVector(assembly.cupGroup.worldPosition),
        formatMatrixBasis(assembly.cupGroup.worldTransform, { indent: 4 }),
        formatMatrixBasis(assembly.cup.worldTransform, { indent: 4 }),
        formatMatrixBasis(assembly.liner.worldTransform, { indent: 4 }),
    );
}

function logStemTransforms(message: string, assembly: HipPlannerAssembly) {
    logValidation(
        '%s\n' +
        '  position: %s\n' +
        '  stem-group transform:\n%s\n' +
        '  head transform:\n%s\n' +
        '  stem transform:\n%s',
        message,
        formatVector(assembly.stemGroup.worldPosition),
        formatMatrixBasis(assembly.stemGroup.worldTransform, { indent: 4 }),
        formatMatrixBasis(assembly.head.worldTransform, { indent: 4}),
        formatMatrixBasis(assembly.stem.worldTransform, {indent: 4}),
    );
}

/**
 * Get the initial position for the acetabular-group. This is the centre-of rotation of the head inside the fitted cup,
 * in world-space. The cup-group (cup and liner) rotate around this point. Also, when the stem-assembly is
 * retracted it is moved so its position: which corresponds to the centre of the head matches this point.
 *
 * The head component on the API is in it's 'native' position: relative to the stem which is fitted to the native
 * position of the femur. This means we can't use the initial transform of the head component to find the
 * head-centre position we need, and so we need to get it from the liner.
 */
export function getAcetabularGroupPosition(linerRepresentation: HipLinerRepresentation): Vector3 {
    return asVector3(linerRepresentation.head_centre);
}

/**
 * Get the initial position for the femoral assembly. This is the centre-of rotation of the head of the
 * stem as it is fitted into the femur, in world-space.
 */
export function getFemoralGroupPosition(headRepresentation: HipHeadRepresentation): Vector3 {
    return getPosition(ViewerUtils.makeMatrixFromArray(headRepresentation.tmatrix));
}
