import { BodySide } from '@/lib/api/representation/interfaces';
import { cupWorldPosition, templatedHeadCentre } from '@/hipPlanner/assembly/controllers/hipPlannerAssembly';
import { FemoralAssembly, HipPlannerAssembly } from '@/hipPlanner/assembly/HipPlannerAssembly';
import LPS from '@/lib/base/LPS';
import assert from 'assert';
import { angleInPlanarProjection } from '@/lib/geometry/angle';
import { areCollinear, orthogonalUnit } from '@/lib/geometry/vector3';
import anylogger from 'anylogger';
import { Vector3 } from 'three';
import { AnatomicAxes } from '@/lib/api/resource/case/study/AnatomicAxes';
import { LegLengthAndOffset, ResectionDistance } from '@/hipPlanner/stores/planner/hipPlannerStore';
import {
    calculateLTResectionDistance,
} from '@/hipPlanner/assembly/controllers/adjustments/calculateLTResectionDistance';
import { logCalculation } from '@/hipPlanner/assembly/controllers/adjustments/logCalculation';

const log = anylogger('adjustment-calculation');

/**
 * Calculate the combined change to leg-length and offset relative to the preoperative state of the
 * femur.
 *
 * A positive leg-length-change implies the post-operative femur will be inferior relative
 * to its pre-operative position.
 *
 * A positive offset-change implies the post-operative femur will be lateral relative to its
 * pre-operative position.
 */
export function calculateLegLengthAndOffset(assembly: HipPlannerAssembly): LegLengthAndOffset {
    const nativeHipJointCentre = assembly.alignmentCoordinates.worldPosition;
    const operativeSide = assembly.side;

    const stemHead = templatedHeadCentre(assembly);

    // The vector from the native hip-joint-centre to the stem head, which is the centre-of-rotation
    // of the templated femoral component
    const hjcToStemHead = stemHead.clone().sub(nativeHipJointCentre);

    // The vector from the native hip-joint-centre to the cup : the centre-of-rotation of the templated cup
    const hjcToCup = cupWorldPosition(assembly).sub(nativeHipJointCentre);

    // Calculate leg-length and offset changes due to the femoral component (stem and head)
    // LL and offset changes due to the femoral component are always calculated in CT coordinates

    // Leg-length increases as the templated-hjc of the stem moves in a superior direction
    const femoralLegLengthChange = hjcToStemHead.dot(LPS.Superior);

    // Offset increases as the templated-hjc of the stem moves in a medial direction
    const femoralOffsetChange = hjcToStemHead.dot(LPS.medial(operativeSide));

    // Calculate leg-length and offset changes due to the acetabular component (cup and liner)
    // LL and offset changes due to the acetabular component are calculated in coordinates that correspond
    // to the current alignment-mode

    const alignedInferior = assembly.alignmentCoordinates.z.worldDirection.negate();
    const alignedLeft = assembly.alignmentCoordinates.x.worldDirection;
    const alignedLateral = operativeSide === BodySide.Left ? alignedLeft : alignedLeft.negate();

    // Leg-length increases as the templated-hjc of the cup moves in an inferior direction
    const acetabularLegLengthChange = hjcToCup.dot(alignedInferior);

    // Offset increases as the templated-hjc of the stem moves in a lateral direction
    const acetabularOffsetChange = hjcToCup.dot(alignedLateral);

    const result = {
        legLengthChange: femoralLegLengthChange + acetabularLegLengthChange,
        offsetChange: femoralOffsetChange + acetabularOffsetChange,
    };

    logCalculation('Femoral LL and offset calculation:', [
        ['native-hjc', nativeHipJointCentre],
        ['stem-head', stemHead],
        ['native-hjc to stem-head', hjcToStemHead],
        ['femoral leg-length change', femoralLegLengthChange],
        ['femoral offset change', femoralOffsetChange],
    ]);
    logCalculation('Acetabular LL and offset calculation:', [
        ['native hjc', nativeHipJointCentre],
        ['native hjc to cup', hjcToCup],
        ['aligned inferior', alignedInferior],
        ['aligned lateral', alignedLateral],
        ['acetabular leg-length change', acetabularLegLengthChange],
        ['acetabular offset change', acetabularOffsetChange],
    ]);
    logCalculation('Combined leg-length and offset change:', [
        ['leg-length change', result.legLengthChange],
        ['offset-change', result.offsetChange],
    ]);

    return result;
}

/**
 * Calculate the anteversion of the stem.
 *
 * See `HipStemTemplatorUtil.calculate_stem_anteversion` for the equivalent python function.
 */
export function calculateStemAnteversion(assembly: HipPlannerAssembly): number {
    // We should only be calling this function when manual-stem-positioning is enabled, which means the
    // stem-neck axis is available
    assert.ok(assembly.stemNeckAxis);

    const stemAxis = assembly.stemNeckAxis?.worldDirection;
    const { posterior } = femoralShaftAxes(assembly.femoralAssembly);
    const condylarAxis = assembly.femoralAssembly.anteversionCondylarAxis.worldDirection;

    return angleInPlanarProjection(stemAxis, condylarAxis, posterior.negate());
}

/**
 * Calculate the femur resection distance
 *
 * See `HipStemFitEvaluationUtil.resection_distances` for the equivalent python function
 */
export function calculateResectionDistance(assembly: HipPlannerAssembly): ResectionDistance {
    return {
        lt: calculateLTResectionDistance(assembly),
    };
}

/**
 * Create a set of anatomical axes with a superior axis corresponding to the femoral-shaft-axis.
 *
 * This is intended to be functionally equivalent to the shaft-plane used to calculate the stem-anteversion
 * in the server-side code. See `HipStemTemplatorUtil.make_shaft_plane`.
 */
function femoralShaftAxes(assembly: FemoralAssembly): AnatomicAxes {
    const superior = assembly.shaftAxis.worldDirection;

    let approxPosterior: Vector3;
    if (areCollinear(superior, LPS.Posterior)) {
        log.warn('Femoral shaft axis is exactly in posterior direction');
        approxPosterior = LPS.Inferior;
    } else {
        approxPosterior = LPS.Posterior;
    }

    const left = orthogonalUnit(approxPosterior, superior);
    const posterior = orthogonalUnit(superior, left);
    return { left, posterior, superior };
}
